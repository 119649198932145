<script>
import Layout from "../../layouts/main";
import SessionLayout from '../../layouts/sessionLayout';
import moment from 'moment';
import userService from '../../../helpers/userService'

/**
 * Starter component
 */
export default {
  page: {
    title: "Session Info",
  },
  name: "Sessiondetails",
  components:{
    Layout,
    SessionLayout
  }, 
  async created(){
    this.role = userService.methods.userRole();
    await this.$store.dispatch('session/getSessionInfo',this.$route.params.session).then(()=>{
        this.profileData = this.$store.getters['session/getSession'];
    }).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch('statistics/getSessionStat',this.$route.params.session).then(()=>{
        this.number = this.$store.getters['statistics/sessionCount'];
    }).catch( err => {
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
  },
  data() {
    return {
      profileData:{},
      number: 0,
      role: null,
    };
  },
  filters:{
    formatDate(value){
      if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
      }
    }
  }
};
</script>

<template>
  <Layout>
    <SessionLayout :session="profileData">
        <div>
            <h5 class="mb-3 text-uppercase bg-light p-2">
                <i class="mdi mdi-account-circle mr-1 "></i> Basic Info
            </h5>
            <table class="table table-borderless table-sm">
            <tbody class="row">
                <tr class="col-md-6">
                <th scope="row">Session Date:</th>
                <td v-if="profileData.date_time">
                    {{ profileData.date_time | formatDate}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Center:</th>
                <td v-if="profileData.center">
                    {{ profileData.center.name}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Year:</th>
                <td v-if="profileData.educational_year">
                    {{ profileData.educational_year.number + ' - ' + profileData.educational_year.description}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Lecture:</th>
                <td v-if="profileData.chapter">
                    {{ profileData.chapter.name }}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Total attended:</th>
                <td v-if="number">
                    {{ number }}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Creator:</th>
                <td v-if="profileData.assistant_createsession">
                    {{ profileData.assistant_createsession.name }}
                </td>
                </tr>
            </tbody>
            </table>
            <b-container class="bv-example-row bv-example-row-flex-cols">
            <b-row>
                <b-col class="col-md-4 offset-md-8 offset-0">
                <router-link :to="'/sessions/'+ this.$route.params.session +'/edit'" v-if="this.role == 0">
                  <b-button variant="outline-primary" pill>
                  <i class="fas fa-pen mr-1"></i>
                    Edit 
                  </b-button>
                </router-link>
                <router-link :to="'/sessions/'+ this.$route.params.session +'/takeAttendance'" class="ml-1">
                  <b-button variant="outline-secondary" pill>
                  <i class="fas fa-user-check mr-1"></i>
                    Attendance 
                  </b-button>
                </router-link>
                </b-col>
            </b-row>
            </b-container>
            
        </div>
    </SessionLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
